<template>
  <div>
    <CompaniesTable />
  </div>
</template>

<script>
import CompaniesTable from '@/components/Dashboard/Companies/CompaniesTable'
export default {
  components: {
    CompaniesTable,
  },
}
</script>

<style></style>
