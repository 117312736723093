<template>
  <header class="mb-4 p-5 flex flex-col bg-white rounded-lg shadow-lg">
    <div class="flex">
      <h2 class="text-left text-md text-gray-600">
        <b>Filtros de búsqueda</b>
      </h2>
    </div>
    <div class="mb-5 max-w-4xl flex flex-col sm:flex-row sm:items-end gap-2">
      <BaseInput
        type="text"
        label="Nombre"
        placeholder="Ej: 1144578546"
        v-model="query.name"
      />
      <BaseInput
        type="text"
        label="Código SFC"
        placeholder="Ej: 1144578546"
        v-model="query.sfc_code"
      />
      <BaseInput
        type="text"
        label="Tipo SFC"
        placeholder="Ej: 1144578546"
        v-model="query.sfc_company_type"
      />
    </div>
    <div class="flex flex-col items-center sm:flex-row gap-2">
      <Button class="h-10 w-44" @click.native="setQuery">
        <a-icon type="search" /> Buscar
      </Button>
      <Button
        v-if="isFiltered"
        :danger="true"
        class="h-10 w-44"
        @click.native="deleteFilters"
      >
        <a-icon type="delete" /> Eliminar filtros
      </Button>
      <Button
        styles="secondary"
        :invert="true"
        class="h-10 w-44"
        @click.native="$router.push({ path: 'company', append: true })"
      >
        <a-icon type="plus" /> Nueva entidad
      </Button>
    </div>
  </header>
</template>

<script>
let querySchema = {
  name: undefined,
  sfc_code: undefined,
  sfc_company_type: undefined,
}
export default {
  props: {
    showButton: Boolean,
    loading: Boolean,
    search: String,
  },
  data() {
    return {
      query: { ...querySchema },
    }
  },
  computed: {
    isFiltered() {
      const query = this.$route.query
      for (const q in query) {
        if (query[q] && q !== 'page' && q !== 'page_limit') {
          return true
        }
      }
      return false
    },
  },
  methods: {
    setQuery() {
      const newQuery = { ...this.$route.query, ...this.query }
      this.$router.push({ query: newQuery })
    },
    deleteFilters() {
      const newQuery = { page: 1, page_limit: 20 }
      this.$router.push({ query: newQuery })
      this.query = { ...querySchema }
    },
  },
}
</script>

<style></style>
